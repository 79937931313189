import { Box, Button, Card, Container, Modal, Typography } from "@mui/material";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import ColorfulBg from "../../assets/Images2/colorful_bg.svg";
import dayjs from "dayjs";
import { Icons } from "../../assets/Icons";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
function CurrentlyParticipating({ data }) {
  const { id } = useParams();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState({});
  function handleOpen(e) {
    setOpen(true);
  }
  function handleClose() {
    setOpen(false);
  }
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    width: 300,
    minHeight: 450,
    maxHeight: 600,
  };

  return (
    <Box className="my-4">
      <Container>
        <Typography variant="body1" sx={{ fontFamily: "'Poppins', sans-serif", fontWeight: 600 }}>
          Ongoing Games and Quizzes
        </Typography>
      </Container>
      {/* <Grid container spacing={2} style={{ width: '100%' }}> */}
      <Box className="my-5 px-5">
        <Splide
          options={{
            type: "loop",
            rewind: true,
            autoplay: false,
            perMove: 1,
            perPage: data?.length>1?2:1,
            arrows: true,
            gap: 8,
            width: "100%",
              maxWidth:"602px",
            pagination: false,
          }}
        >
          {data?.map((each) => {
            return (
              <SplideSlide key={each?.id}>
                <Card
                  elevation={0}
                  style={{
                    height: "20rem",
                    width: "100%",
                    backgroundColor: "#ffffff",
                    border: "1px solid #CAE8F8",
                    borderRadius: "1rem",
                    
                  }}
                  onClick={(e) => {
                    if (!e.target.closest("button")) {
                      navigate(each.quizType === "quiz" ? `/event/${id}/quizplay?quiz_id=${each?.id}` : `/event/${id}/gameplay?id=${each?.id}`);
                    }
                    setSelected(each);
                  }}
                >
                  <Box
                    style={{
                      backgroundImage: each?.avatar ? `url("${each?.avatar}")` : `url("${ColorfulBg}")`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      height: "8rem",
                      position: "relative",
                    
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: "#CAE8F8",
                        fontSize: "0.8rem",
                        padding: "0.2rem 0.7rem",
                        borderTopLeftRadius: "0.5rem",
                        borderBottomLeftRadius: "0.5rem",
                        width: "fit-content",
                        fontFamily: "poppins",
                        position: "absolute",
                        bottom: 10,
                        right: 0,
                      }}
                    >
                      Ending: {dayjs(each?.quiz_end_date).format("DD/MM/YYYY")}
                    </Box>
                  </Box>
                  <Box className="p-3" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "12rem" }}>
                    <Typography variant="body1" sx={{ fontFamily: "'Poppins', sans-serif", fontWeight: 600 }}>
                      {each?.quiz_title}
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "'Poppins', sans-serif",
                        fontWeight: 400,
                        color: "black",
                        fontSize: "12px",
                        display: "-webkit-box",
                        WebkitLineClamp: 3,
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                      variant="body2"
                    >
                      {each?.quiz_description}
                    </Typography>
                    <Box className="flex justify-end">
                      <Button sx={{ textTransform: "none", color: "#277BA0", fontSize: "0.7rem", fontWeight: 600 }} onClick={handleOpen}>
                        See More
                      </Button>
                    </Box>
                    <Box>
                      <Typography sx={{ fontFamily: "'Poppins', sans-serif", fontWeight: 500, color: "black", fontSize: "12px" }} variant="body2">
                        Current Score
                      </Typography>
                      <Box className="flex  gap-1 my-1">
                        <img src={Icons?.Dice} alt="" /> <span style={{ fontSize: "1.5rem", fontFamily: "poppins", fontWeight: 600 }}>{each?.current_score}</span>
                      </Box>
                    </Box>
                  </Box>
                </Card>
              </SplideSlide>
            );
          })}
        </Splide>
        <Modal open={open} onClose={handleClose}>
          <Card elevation={0} sx={{ ...style, backgroundColor: "#ffffff", border: "1px solid #CAE8F8", borderRadius: "1rem" }}>
            <Box
              style={{
                backgroundImage: selected?.avatar ? `url("${selected?.avatar}")` : `url("${ColorfulBg}")`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                height: "8rem",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  backgroundColor: "#CAE8F8",
                  fontSize: "0.8rem",
                  padding: "0.2rem 0.7rem",
                  borderTopLeftRadius: "0.5rem",
                  borderBottomLeftRadius: "0.5rem",
                  width: "fit-content",
                  fontFamily: "poppins",
                  position: "absolute",
                  bottom: 10,
                  right: 0,
                }}
              >
                Ending: {dayjs(selected?.quiz_end_date).format("DD/MM/YYYY")}
              </Box>
            </Box>
            <Box className="p-3">
              <Typography variant="body1" sx={{ fontFamily: "'Poppins', sans-serif", fontWeight: 600 }}>
                {selected?.quiz_title}
              </Typography>
              <Typography
                sx={{
                  fontFamily: "'Poppins', sans-serif",
                  fontWeight: 400,
                  color: "black",
                  fontSize: "12px",
                }}
                variant="body2"
              >
                {selected?.quiz_description}
              </Typography>
              <Box>
                <Typography sx={{ fontFamily: "'Poppins', sans-serif", fontWeight: 500, color: "black", fontSize: "12px" }} variant="body2">
                  Current Score
                </Typography>
                <Box className="flex  gap-1 my-1">
                  <img src={Icons?.Dice} alt="" /> <span style={{ fontSize: "1.5rem", fontFamily: "poppins", fontWeight: 600 }}>{selected?.current_score}</span>
                </Box>
              </Box>
            </Box>
          </Card>
        </Modal>
      </Box>
      {/* </Grid> */}
    </Box>
  );
}

export default CurrentlyParticipating;
