import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Layout from "../../components/layout/NewLayout";
import ModalTemplate from "../../components/modal/Modal";
// import RegisterForm from "./RegisterForm/RegisterForm3";
import RegisterForm from "./RegisterForm/RegisterForm4";
// import RegisterForm from "./RegisterForm/RegisterForm";
import { Box, Button, Typography } from "@mui/material";
import Image from "material-ui-image";
import infoIcon from "../../assets/images/info-circle.png";
import qwizLogo from "../../assets/Images2/qwizfun_logo.svg";
import { useFetchLandingPageSettings } from "../../hooks/api/fetch";
import TermsAndConditions from "./termsAndConditions/TermsAndConditions";
function Register() {
  const { id } = useParams();
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const landingPageAPI = useFetchLandingPageSettings(id);
  const [TAC, setTAC] = useState(false)
  const [terms, setTerms]= useState([])
  useEffect(() => {
    if (landingPageAPI?.data?.landing_page_setting?.landing_page_header)
      sessionStorage.setItem(
        "logo",
        landingPageAPI?.data?.landing_page_setting?.landing_page_header
      );
      const termsSection=landingPageAPI?.data?.landing_page_setting?.landing_page_other_sections?.find(item=>item?.section_name==="tac")
      const termsText=!!termsSection?.text?JSON.parse(termsSection?.text || ""):[]
      if (termsText?.length >0){
setTerms(termsText?.map(item=>item?.key))
      }

    }, [landingPageAPI?.data?.landing_page_setting?.landing_page_header]);



  if (landingPageAPI?.isLoading) {
    return <>Loading... Please wait</>;
  }
  return (
    <Layout
      // logo={
      //   landingPageAPI?.data?.landing_page_setting?.landing_page_header
      //     ?.header_logo || qwizLogo
      // }
      logo={qwizLogo}
      url={`/${
        window.location.href.includes("corporate") ? "corporate" : "event"
      }/${id}`}
    >
      <Box
        mt={{
          xs: 2,
          md: 2,
        }}
        // p={2}
        sx={{
          minHeight: "calc(100vh - 200px)",
        }}
      >

<ModalTemplate open={TAC} handleClose={()=>setTAC(false)}>
        <TermsAndConditions
        //  title={"Access Games"} 
         title={"Terms and Conditions"} terms={terms} />
</ModalTemplate>

        <Typography variant="h6" my={2} px={2} align="left" fontWeight={600}>
          {" "}
          Create An Account
        </Typography>
        <RegisterForm
          setErrorMessage={setErrorMessage}
          setOpenErrorModal={setOpenErrorModal}
          setTAC={setTAC}
        />
        <RegisterError
          openModal={openErrorModal}
          setOpenModal={setOpenErrorModal}
          message={errorMessage}
        />
      </Box>
    </Layout>
  );
}

export default Register;
function RegisterError({ openModal, setOpenModal, message }) {
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <ModalTemplate open={openModal} handleClose={handleCloseModal}>
      <Box mt={1} py={1} sx={{ display: "flex", justifyContent: "center" }}>
        <Box sx={{ width: "48px", height: "48px" }}>
          <Image src={infoIcon} color="transparent" />
        </Box>
      </Box>
      <Typography variant="body1" align="center">
        {message}
      </Typography>
      <Box py={1} sx={{ display: "flex", justifyContent: "center" }}>
        <Button variant="contained" color="success" onClick={handleCloseModal}>
          Close
        </Button>
      </Box>
    </ModalTemplate>
  );
}
