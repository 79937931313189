import React from 'react'
import CloseIcon from '@mui/icons-material/Close';

function TermsAndConditions({title, subtitle, terms}) {
  // const [title, setTitle] = useState('Terms and Condition')
  // const [subTitle, setsubTitle] = useState('Terms and Condition')
  // const [terms, setTerms] = useState({})


  return (
    <div className=' px-5 p-5 mx-5  bg-white rounded-lg '>
      {/* <div className='text-end'><CloseIcon /></div> */}
      
      <h2 className='text-center text-xl uppercase font-semibold'>{title}</h2>
      <h3 className='text-center text-lg capitalize mt-1 font-semibold'>{subtitle}</h3>
      <ul className='ml-5'>
      {terms?.map((term, index) => (
        <li key={term}>
            <span>{index + 1}. </span>{term}
        </li>
      ))}
    </ul>
    </div>
  )
}

export default TermsAndConditions
