import React, { useEffect, useState } from "react";
import { Box, Button, FormControl, FormGroup, FormControlLabel, Checkbox, Grid, InputLabel, MenuItem, Paper, Select, Stack, TextField, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { Form, Formik, ErrorMessage } from "formik";
import { Link, redirect, useNavigate, useParams } from "react-router-dom";
import { useRegistration } from "../../../hooks/api/post";
import * as Yup from "yup";
import { useFetchDepartments, useFetchCountries, useFetchLandingPageSettings } from "../../../hooks/api/fetch";

function RegisterForm({ setErrorMessage, setOpenErrorModal , TAC, setTAC}) {
  const navigate = useNavigate();
  const { id } = useParams();
  // console.log({ id });
  const registerApi = useRegistration();
  const departments = useFetchDepartments();
  const countries = useFetchCountries();
  const registerSchema = Yup.object().shape({
    first_name: Yup.string().min(2, "Name is too Short!").max(70, "Name is too Long!").required("Name is Required"),
    last_name: Yup.string().min(2, "Name is too Short!").max(70, "Name is too Long!").required("Name is Required"),

    display_name: Yup.string().min(6, "Display Name should be at least 6 characters ").max(30, "Display Name is Too Long!").required("Display Name is Required"),
    email: Yup.string()
      .email("Please enter a valid email address")
      // .matches(
      //   /.+@theaccesscorporation.com$/,
      //   "Email address must end with @theaccesscorporation.com"
      // )
      .matches(/theaccesscorporation.com|accessbankplc.com|accesspension.ng/i, "Email address must be a valid work email")
      .required("Email is Required")
      .test("first-last-name", "Email must include First name and Last Name ", (value, ctx) => {
        let fullname = ctx.parent.first_name + "." + ctx.parent.last_name;
        if (!value.toLowerCase().includes(fullname.toLowerCase())) {
          return false;
        }
        return true;
        // console.log({ value, ctx });
      }),
  });
  const [registerPayload, setRegisterPayload] = useState({
    success: true,
    message: "Domain record successfully fetched!",
    data: {
      id: "faa2a1c7-0157-44d4-acd3-27ae3b351a2b",
      cliient_id: 1,
      auth_type: null,
      site_name: "ASNE x 9ijakids Quiz",
      site_logo: "",
      loginField: "phone number",
      site_instruction: "Enter your phone number to play and win amazing prizes!!! 😊🥳",
      mail_suffix: null,
      enable_domain_mail_validation: false,
      enable_phone_number: true,
      enable_display_name: true,
      enable_group: false,
      isDisclaimer: false,
      to_register: true,
      require_first_name: true,
      require_last_name: true,
      require_email: true,
      enable_term_condition: true,
      enable_account_permission: true,
      enable_full_name_leaderboard: true,
      enable_individual_leaderboard: true,
      enable_team_leaderboard: true,
      createdAt: "2023-11-11T17:47:52.000Z",
      updatedAt: "2023-11-11T17:47:52.000Z",
    },
  });
  const landingPageAPI = useFetchLandingPageSettings(id || "093876");
  useEffect(() => {
    if (landingPageAPI.data) {
      setRegisterPayload({ data: landingPageAPI.data.domain });
    }
  }, [landingPageAPI.data]);
  if (landingPageAPI.isLoading) {
    return <>Please Wait...</>;
  }
  if (landingPageAPI.data?.domain?.to_register === 0) {
    navigate("../login");
  }
  return (
    <Box elevation={6}>
      <Box p={{ xs: 2, md: 2 }}>
        <Typography variant="h6" fontSize={12}>
          Sign Up with correct details
        </Typography>
        <Box>
          <Formik
            initialValues={{
              first_name: "",
              last_name: "",
              event_code: id,
              display_name: "",
              loginField: landingPageAPI.data?.domain?.auth_type || "email",
              group_id: "",
              country: "",
              email: "",
              redirectUrl:window.location.href.replace("register", "login")
                        }}
            // validationSchema={registerSchema}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true);

              registerApi.mutate(values, {
                onSuccess: (response) => {

                  if(response?.paymentlink && response?.isPaid){
                 return window.location.href=response?.paymentlink
                  }

                  toast.success("Success!");
                  const res = response.data;
                  console.log({ res });
                  const userInfo = JSON.stringify({
                    token: res?.token,
                    id: res?.id,
                    displayName: res?.display_name,
                    name: res?.name,
                    avatar: res?.avatar,
                    first_login: res?.first_login,
                  });

                  sessionStorage.setItem("ccAuth", userInfo);
                  sessionStorage.setItem("displayName", res?.subcriber?.display_name);
                  localStorage.setItem("newbie", "yes");
                  navigate(`/${window.location.href.includes("corporate") ? "corporate" : "event"}/${id}/onboarding`);
                },
                onError: (err) => {
                  console.log({ err: err.response?.data?.message });
                
                  setErrorMessage(err?.response?.data?.message);
                  setOpenErrorModal((err) => !err);
                },
                onSettled: () => {
                  setSubmitting(false);
                },
              });
            }}
          >
            {({ isSubmitting, setFieldValue }) => (
              <Form>
                {/* form field */}
                <Box
                  sx={{
                    // minHeight: "calc(100vh - 400px)"
                    pb: 2,
                  }}
                >
                  <Grid container xs={12} justifyContent="space-between">
                    {/* firstname */}
                    {registerPayload.data.require_first_name === 1 && (
                      <Grid item xs={12} md={5.6}>
                        <Box my={1}>
                          <TextField fullWidth data-test-id="firstname" label="First Name" variant="outlined" onChange={(e) => setFieldValue("first_name", e.target.value.trim())} />
                          <Box sx={{ color: "error.main", width: "100%" }}>
                            <ErrorMessage name="first_name" />
                          </Box>
                        </Box>
                      </Grid>
                    )}
                    {/* lastname */}
                    {registerPayload.data.require_last_name === 1 && (
                      <Grid item xs={12} md={5.6}>
                        <Box my={1}>
                          <TextField fullWidth data-test-id="lastname" label="Last Name" variant="outlined" onChange={(e) => setFieldValue("last_name", e.target.value.trim())} />{" "}
                          <Box sx={{ color: "error.main", width: "100%" }}>
                            <ErrorMessage name="last_name" />
                          </Box>
                        </Box>
                      </Grid>
                    )}

                    <TextInputField size={{ xs: 12, md: 5.6 }} label={"Mobile Number"} name="phone_number" api={registerPayload.data.enable_phone_number === 1} setFieldValue={setFieldValue} />
                    <Grid item xs={12} md={5.6}>
                      {/* display name */}
                      {registerPayload.data.enable_display_name === 1 && (
                        <Box my={1}>
                          <TextField fullWidth data-test-id="display name" label="Display Name" variant="outlined" onChange={(e) => setFieldValue("display_name", e.target.value.trim())} />{" "}
                          <Box sx={{ color: "error.main", width: "100%" }}>
                            <ErrorMessage name="display_name" />
                          </Box>
                        </Box>
                      )}
                    </Grid>
                    <Grid item xs={12} md={5.6}>
                      {/* email */}
                      {registerPayload.data.require_email === 1 && (
                        <Box my={1}>
                          <TextField
                            fullWidth
                            data-test-id="email"
                            label={window.location.href.includes("corporate") ? "Work Email" : "Email"}
                            variant="outlined"
                            onChange={(e) => setFieldValue("email", e.target.value.trim())}
                          />
                          <Box sx={{ color: "error.main" }}>
                            <ErrorMessage name="email" />
                          </Box>
                        </Box>
                      )}
                    </Grid>

                    {landingPageAPI.data?.custom_fields?.map((field, index) =>
                    field?.input_type === "input" ? (
                      // <TextInputField
                      //   api={true}
                      //   size={5.8}
                      //   label={field?.field_name}
                      //   name={field?.field_name}
                      //   setFieldValue={setFieldValue}
                      // />

                      <Grid item xs={5.8}>
                        {/* Mobile */}

                        <Box my={2}>
                          <TextField
                            fullWidth
                            data-test-id={field?.field_name}
                            label={field?.field_name}
                            variant="outlined"
                            required={field?.is_required}
                            onChange={(e) => {
                              setFieldValue(
                                `custom_fields[${[index]}].custom_field_id`,
                                field?.id
                              );
                              setFieldValue(
                                `custom_fields[${[index]}].field_value`,
                                e.target.value
                              );
                            }}
                          />{" "}
                        </Box>
                      </Grid>
                    ) : field?.input_type === "drop-down" ? (
                      <Grid item xs={5.8} mt={2}>
                        <FormControl fullWidth>
                          <InputLabel id="">
                            {field?.field_name} {field?.is_required && "*"}
                          </InputLabel>
                          <Select
                            required={field?.is_required}
                            labelId={field?.field_name}
                            data-test-id={field?.field_name}
                            id={field?.field_name}
                            label={field?.field_name}
                            onChange={(e) => {
                              setFieldValue(
                                `custom_fields[${[index]}].custom_field_id`,
                                field?.id
                              );
                              setFieldValue(
                                `custom_fields[${[index]}].field_value`,
                                e.target.value
                              );
                            }}
                          >
                            <MenuItem value="">Select</MenuItem>
                            {(JSON?.parse(field?.field_values) || []).map(
                              (dept, id) => (
                                <MenuItem value={dept} key={id}>
                                  {dept}
                                </MenuItem>
                              )
                            )}
                          </Select>
                        </FormControl>
                      </Grid>
                    ) : null
                  )}



                  </Grid>

                  <Stack direction="row" justifyContent="space-between" gap={"15px"}></Stack>
                


                  <Grid container xs={12}>
                    {/* Custom Checkbox */}
                     
                     {landingPageAPI.data?.custom_fields?.map((field, index) =>
                      field?.input_type === "checkbox" 
                     && (
                      <Grid item xs={12}>
                        <Box>
                          <div className="flex items-center">
                          <CheckboxField
                            setFieldValue={setFieldValue}
                            name="tac"
                            sx={{m:0, border:"1px solid green"}}
                            label={field?.label}
                            required={field?.is_required}
                          />
                 
                 
                  {/* <p className=" ml-[-15px] font-medium">I have read and accepted the <button type="button" onClick={() => setTAC(true)} className=" text-green-600 underline">terms and conditions</button>.</p> */}
                </div>
                        </Box>
                      </Grid>
                    ))}
                     {landingPageAPI.data?.event?.is_paid===1 && (
                      <Grid item xs={12}>
                        <Box>
                          <div className="flex items-center">
                          <TextField
                           
                            name="tac"
                            sx={{m:0, border:"1px solid green"}}
                            variant="filled"
                            disabled
                            label={"Amount Payable"}
                           value={landingPageAPI.data?.event?.amount?.toLocaleString()}
                          />
                 
                 
                  {/* <p className=" ml-[-15px] font-medium">I have read and accepted the <button type="button" onClick={() => setTAC(true)} className=" text-green-600 underline">terms and conditions</button>.</p> */}
                </div>
                        </Box>
                      </Grid>
                    )}

                    {/* is Disclaimer */}
                    {registerPayload?.data?.enable_account_permission === 1 && (
                      <Grid item xs={12}>
                        <Box>
                          <CheckboxField setFieldValue={setFieldValue} name="disclaimer" label={"I agree to the terms and conditions"} />
                        </Box>
                      </Grid>
                    )}
                    {/* is Disclaimer */}
                    {/* {registerPayload?.data?.enable_term_condition === 1 && (
                      <Grid item xs={12}>
                        <Box>
                          <CheckboxField setFieldValue={setFieldValue} name="tac" label={"I agree to the terms and conditions"} />
                        </Box>
                      </Grid>
                    )} */}
                    {registerPayload?.data?.enable_term_condition === 1 && (
                      <Grid item xs={12}>
                        <Box>
                          <div className="flex items-center">
                          <CheckboxField
                            setFieldValue={setFieldValue}
                            name="tac"
                            sx={{m:0, border:"1px solid green"}}
                            required={true}
                            // label={"I agree to the terms and conditions"}
                          />
                 
                 
                  <p className=" ml-[-15px] font-medium">I have read and accepted the <button type="button" onClick={() => setTAC(true)} className=" text-green-600 underline">terms and conditions</button>.</p>
                </div>
                        </Box>
                      </Grid>
                    )}
                  </Grid>
                </Box>

                <Box
                  mt={2}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    fullWidth
                    type="submit"
                    disabled={isSubmitting}
                    variant="contained"
                    // bgcolor="#277BA0"
                    // sx={{ backgroundColor: "rgb(113 51 186 ) !important" }}
                  >
                    {isSubmitting ? "Registering" : landingPageAPI.data?.event?.is_paid===1?"Make Payment":"Register"}
                  </Button>
                </Box>
                <Box my={2} sx={{ position: "relative", zIndex: 5 }}>
                  <Typography variant="body2" align="center">
                    Already have an account?
                    <Box component="span" color="green">
                      <Link to={`/${window.location.href.includes("corporate") ? "corporate" : "event"}/${id}/login`}> Login</Link>
                    </Box>
                  </Typography>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
    </Box>
  );
}

export default RegisterForm;

function TextInputField({ api, size, setFieldValue, name, label }) {
  return (
    <Grid item xs={size.xs || 5.8} md={size.md || 5.8}>
      {/* Mobile */}
      {api && (
        <Box my={1}>
          <TextField fullWidth data-test-id={name} label={label} variant="outlined" onChange={(e) => setFieldValue(name, e.target.value.trim())} />{" "}
          <Box sx={{ color: "error.main", width: "100%" }}>
            <ErrorMessage name={name} />
          </Box>
        </Box>
      )}
    </Grid>
  );
}

function CheckboxField({ setFieldValue, required = false, name, label, labelPlacement }) {
  return (
    <FormGroup>
      <FormControlLabel control={<Checkbox onChange={(e) => setFieldValue(name, e.target.checked)} />} label={label} labelPlacement={labelPlacement || "end"} />
    </FormGroup>
  );
}
