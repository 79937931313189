import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { Form, Formik, ErrorMessage } from "formik";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useLogin } from "../../hooks/api/post";
import { useFetchLandingPageSettings } from "../../hooks/api/fetch";
function RegisterForm() {
  const { id } = useParams();
  const loginApi = useLogin();
  const navigate = useNavigate();
  const loginSchema = Yup.object().shape({
    unique_id: Yup.string()
      .email("Please enter a valid email address")
      // .matches(
      //   /.+@theaccesscorporation.com$/,
      //   "Email address must end with @theaccesscorporation.com"
      // )
      .matches(/access|9ijakids/i, "Email address must be a valid work email")
      .required("Email is Required"),
  });

  const landingPageAPI = useFetchLandingPageSettings(id || "093876");
  let [authType, setAuthType] = useState(
    landingPageAPI.data?.domain?.auth_type
  );
  useEffect(() => {
    if (landingPageAPI.data) {
      setAuthType(landingPageAPI.data?.domain?.auth_type);
    }
  }, [landingPageAPI.data]);

  return (
    <Box elevation={6}>
      <Box p={{ xs: 2, md: 2 }}>
        <Typography variant="h6" fontSize={12} sx={{ color: "#000000" }}>
          {landingPageAPI.data?.domain?.auth_type === "Mobile"
            ? "Login With your Mobile Number"
            : landingPageAPI.data?.domain?.auth_type === "email"
            ? "Login With your email"
            : " Log In With your passcode"}
        </Typography>
        <Box>
          <Formik
            // validationSchema={loginSchema}
            initialValues={{
              display_name: "",
              email: "",
              unique_id: "",
              event_code: id,
              redirectUrl:window.location.href
            }}
            onSubmit={(values, { setSubmitting }) => {
              loginApi.mutate(values, {
                onSuccess: (response) => {

                  if(response?.paymentlink && response?.isPaid){
                    return window.location.href=response?.paymentlink
                     }

                  toast.success("Success!");
                  const res = response.data;
                  console.log({ res });

                  const userInfo = JSON.stringify({
                    token: res?.token,
                    userPassport: values.user_passport,
                    id: res?.user?.id,
                    displayName: res?.user?.display_name,
                    name: res?.user?.name,
                    ageRange: res?.user?.age_range,
                    avatar: res?.user?.avatar,
                    first_login: res?.user?.first_login,
                  });

                  console.log(JSON.parse(userInfo));
                  if (res.user.display_name === "null") {
                    sessionStorage.setItem("closeCreate", false);
                  }
                  // sessionStorage.setItem(
                  //   "quizID",
                  //   "24df12d9-1116-4b57-a458-c2e39d6fd36a"
                  // );
                  sessionStorage.setItem("ccAuth", userInfo);
                  sessionStorage.setItem(
                    "displayName",
                    res?.user?.display_name
                  );
                  if (res?.user?.first_login === 0) {
                    sessionStorage.setItem("newbie", "no");
                  } else {
                    sessionStorage.setItem("newbie", "yes");
                    localStorage.setItem("hideHelp", "false");
                  }
                  navigate(
                    `/${
                      window.location.href.includes("corporate")
                        ? "corporate"
                        : "event"
                    }/${id}/onboarding`
                  );
                },
                onError: (err) => {
                  console.log({ err: err.response?.data?.message });
                  if(err.response?.data?.paymentlink){
                   return window.location.href=err.response?.data?.paymentlink
                  }
                  toast.error(`${err.response?.data?.message}`);
                  // toast.error(`${err.response?.data?.message}`);
                },
                onSettled: () => {
                  setSubmitting(false);
                },
              });
              // console.log({ values });
            }}
          >
            {({ isSubmitting, setFieldValue }) => (
              <Form>
                {/* TExt fields */}
                <Box
                  sx={{
                    // minHeight: "calc(100vh - 440px)"
                    pb: 2,
                  }}
                >
                  <Box my={2}>
                    <TextField
                      required
                      fullWidth
                      type={authType !== "email" ? "text" : "email"}
                      data-test-id={"authType"}
                      label={authType?.toUpperCase()}
                      variant="outlined"
                      onChange={(e) => {
                        setFieldValue("email", e.target.value.trim());
                        setFieldValue("unique_id", e.target.value.trim());
                      }}
                    />
                    <Box sx={{ color: "error.main", width: "100%" }}>
                      <ErrorMessage name="unique_id" />
                    </Box>
                  </Box>
                </Box>

                {/* <Box>
                  <TextField
                    fullWidth
                    data-test-id="password"
                    label="Password"
                    variant="outlined"
                    onChange={(e) => setFieldValue("password", e.target.value)}
                  />
                </Box> */}
                <Box
                  mt={2}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    fullWidth
                    type="submit"
                    variant="contained"
                    disabled={isSubmitting}
                    data-test-id="login"
                    // bgcolor="#277BA0"
                    // sx={{ backgroundColor: "rgb(113 51 186 )  !important" }}
                  >
                    {isSubmitting ? "Logging In" : "Login"}
                  </Button>
                </Box>
                <Box my={2}>
                  {landingPageAPI.data?.domain?.to_register === 1 && (
                    <Typography variant="body2" align="center">
                      Don't have an account?
                      <Box component="span" color="primary.main">
                        <Link
                          to={`/${
                            window.location.href.includes("corporate")
                              ? "corporate"
                              : "event"
                          }/${id}/register`}
                        >
                          {" "}
                          Register
                        </Link>
                      </Box>
                    </Typography>
                  )}
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
    </Box>
  );
}

export default RegisterForm;
