import { Box, Button, Card, Container, Grid, Typography } from "@mui/material";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import ColorfulBg from "../../../assets/Images2/colorful_bg.svg";
import { Link, useParams } from "react-router-dom";
import dayjs from "dayjs";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => {
  return {
    card: {
      margin: "1rem 0rem 0.2rem 0rem",
      padding: "0.6rem",
      borderRadius: "1.4rem !important",
    },
    cardContent: {
      backgroundImage: `url("${ColorfulBg}")`,
      borderRadius: "1rem",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center",
      height: "8rem",
    },
    contentOverlay: {
      backgroundColor: "rgba(000, 000, 000,0.5)",
      height: "100%",
      padding: "0 0.5rem",
      paddingTop: "0.7rem",
      borderRadius: "1rem 3rem 3rem 1rem",
      color: "white",
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "column !important",
      overFlow: "hidden !important",
    },
    body1: {
      fontWeight: "600 !important",
      fontSize: "0.9rem !important",
    },
    body2: {
      fontSize: "0.75rem !important",
    },
    datePill: {
      background: "rgba(202, 232, 248, 1)",
      width: "auto",
      padding: "0.35rem",
      fontSize: "0.65rem",
      borderRadius: "1rem 0rem 0rem 1rem",
      color: "black",
    },
    button: {
      color: "white !important",
      fontFamily: "Poppins !important",
      fontSize: "0.75rem !important",
      fontWeight: "500 !important",
      textTransform: "capitalize !important",
      marginRight: "0.5rem !important",
    },
  };
});
function OnGoingCompetitions({ title, data, isOnGoing }) {
  const classes = useStyles();
  const { id } = useParams();
  return (
    <Box sx={{ position: "relative", zIndex: 1, marginTop: "2rem" }}>
      <Container>
        <Typography variant="body1" sx={{ fontFamily: "'Poppins', sans-serif", fontWeight: 600 }}>
          {title}
        </Typography>
        <Splide
          options={{
            type: "loop",
            rewind: true,
            // autoplay: true,
            perMove: 1,
            perPage: 1,
            width: "100%",
            pagination: true,
            arrows: false,
          }}
        >
          {data?.map((event) => {
            return (
              <SplideSlide>
                <Card elevation={1} className={classes.card}>
                  <Box className={classes.cardContent}>
                    <Grid container sx={{ height: "100%" }}>
                      <Grid item md={8} xs={8} className={classes.contentOverlay}>
                        <>
                          <Typography className={classes.body1} variant="body1">
                            {event?.event?.event_name}
                          </Typography>
                          <Typography variant="body2" className={classes.body2}>
                            {event?.event?.event_description}
                          </Typography>
                        </>
                        <Box className="flex flex-row items-end justify-between">
                          <Box className={classes.datePill}>
                            {isOnGoing ? `Ending: ${dayjs(event?.event?.event_end_date).format("DD/MM/YYYY")}` : `Starting: ${dayjs(event?.event_start_date).format("DD/MM/YYYY")}`}
                          </Box>
                          <Link to={event?.url}>
                            <Button className={classes.button}>Join now</Button>
                          </Link>
                        </Box>
                      </Grid>
                      <Grid item md={4} xs={4}></Grid>
                    </Grid>
                  </Box>
                </Card>
              </SplideSlide>
            );
          })}
        </Splide>
      </Container>
    </Box>
  );
}

export default OnGoingCompetitions;
