import React, { useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  ToggleButton,
  TextField,
  Typography,
} from "@mui/material";
import { v4 as uuidv4 } from 'uuid';

import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Card from "../../../components/cardLayout/Card";
function Question({ sx, question, options = [], setSelectedOption , selectedOption, handleNext, setTestData}) {
  // console.log({selectedOption5:selectedOption})
//   useEffect(()=>{
// setSelectedOption(prev=>selectedOption)
//   },[selectedOption])
  return (
    <Box {...sx}>
      {/* Questions */}
      <Box
        height={250}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            // alignContent: "center",
            justifyContent: "center",
          }}
        >
          <Card>
            <Typography variant="h3" color="#277BA0" fontWeight={700} mb={1}>
              Question
            </Typography>
            <Typography sx={{ maxWidth: "300px" }}>{question}</Typography>
          </Card>
        </Box>
      </Box>

      {/* Answers */}
      
        <Multichoice
          options={options || []}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
      handleNext={handleNext}
      setTestData={setTestData}
          />
          
    
        {/* <TextArea 
        setSelectedOption={setSelectedOption}
        
        /> */}
      
    </Box>
  );
}

export default Question;

function Multichoice({ options, setSelectedOption, selectedOption, handleNext, setTestData }) {
  // const [alignment, setAlignment] = React.useState("");

  const handleChange = (event, value,handleNext, setSelectedOption) => {
    setSelectedOption(prev=>value);
handleNext(value)
setTestData(value)
    
  };

  const alpahbets = "ABCDEFG";
  return (
    <Grid container alignItems="center">
      
      {options?.map((option, index) => (
        <Grid item xs={6} key={uuidv4()}>
          <Box>
            <Button
              fullWidth
              // name={option?.value}
              onClick={(e) => {
                handleChange(e, option?.value, handleNext, setSelectedOption)
             
              }}
            >
              <Card
                small
                bgcolor={selectedOption === option?.value ? "#CAE8F8" : "white"}
                sx={{
                  width: "100%",
                  minHeight: "68px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography variant="body1" color="#333" fontSize={12}>
                  <span style={{ fontWeight: 600 }}>{alpahbets[index]}.</span>{" "}
                  {option?.label}
                </Typography>
              </Card>
            </Button>
          </Box>
        </Grid>
      ))}
   
    </Grid>
  );
}

function TextArea({ setSelectedOption }) {
  return (
    <Box className="w-full border-sky-900">
      <Card sx={{ width: "100%" }}>
        <Typography variant="h6" className="pb-3" color="primary.main">
          Answer
        </Typography>
        <TextField
          fullWidth
          multiline
          rows={4}
          onChange={(e) => {
            setSelectedOption(e.target.value);
            console.log(e.target.value);
          }}
        />
      </Card>
    </Box>
  );
}
